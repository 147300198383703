import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useEffect } from 'react';

import { Copy, H2, H3 } from '../../components/atoms/Typography';
import { TwoColumnsIntro } from '../../components/molecules/TwoColumnsIntro';
import { PrimaryButtonLink } from '../../components/atoms/Button/Buttons';
import { Spacer } from '../../components/atoms/Spacer/Spacer';
import { ChevronRightIcon } from '../../components/atoms/Icons';
import { Colors } from '../../styles/colors';

import { Pricing } from '../../components/molecules/Pricing';

const jobTrackerImage1 = '/images/tools/job-tracker-1-job-listing.png';
const jobTrackerImage2 = '/images/tools/job-tracker-2-task-manager.png';
const jobTrackerImage3 = '/images/tools/job-tracker-3-event.png';
import taskManagerIcon from '../../assets/icons/task-manager.svg';
import calendarIcon from '../../assets/icons/organise3.svg';

const JobTracker = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Helmet title="Job Application Tracker" />

      <TwoColumnsIntro image={jobTrackerImage1} isInverted={false}>
        <H3>Stay ahead in your Job Search</H3>
        <H2>Job Application Tracker</H2>
        <Copy>
          Streamline your job search by centralizing and effortlessly tracking all your applications in one organized
          space.
        </Copy>
        <Spacer y={32} />
        <PrimaryButtonLink inline={true} to="/login" iconRight={<ChevronRightIcon color={Colors.White} />}>
          Get Started for Free
        </PrimaryButtonLink>
      </TwoColumnsIntro>

      <TwoColumnsIntro image={jobTrackerImage2} isInverted={true}>
        <FeatureIcon>
          <img alt={'Organise'} src={taskManagerIcon} />
        </FeatureIcon>
        <H2>Tasks Manager</H2>
        <Spacer y={32} />
        <Copy>
          Effortlessly navigate every stage of your journey with our tasks manager, paving the way to secure your dream
          job. <b>Premium users can also enjoy the AI-powered tasks manager.</b>
        </Copy>
        <Spacer y={32} />
        <PrimaryButtonLink inline={true} to="/login" iconRight={<ChevronRightIcon color={Colors.White} />}>
          Create your First Task
        </PrimaryButtonLink>
      </TwoColumnsIntro>

      <TwoColumnsIntro image={jobTrackerImage3} isInverted={false}>
        <FeatureIcon>
          <img alt="Job Applications Events" src={calendarIcon} />
        </FeatureIcon>
        <H2>Job Application Events</H2>
        <Spacer y={32} />
        <Copy>
          Stay on top of important interview dates and crucial details, ensuring you're fully prepared to showcase your
          talents and land that dream job. <b> Premium users get daily and weekly email reminders.</b>
        </Copy>
        <Spacer y={32} />
        <PrimaryButtonLink inline={true} to="/login" iconRight={<ChevronRightIcon color={Colors.White} />}>
          Create your First Task
        </PrimaryButtonLink>
      </TwoColumnsIntro>

      <Spacer y={62} />

      <Pricing />

      <Spacer y={62} />
    </>
  );
};

export default JobTracker;

export const FeatureIcon = styled.div`
  display: flex;
  width: 100px;
  height: 100px;
  flex: 1 0 120px;
  justify-content: center;
`;
